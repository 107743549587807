.page{
    padding: 2%;
    background-color: white;
}

.header {
    position: relative;
    overflow: hidden;
    padding: 10px 13px;
    margin-top: 0px !important;
}

.header .logo{
   float: left;
   font-size: 25px;
  font-weight: bold;
}

.successfulBooking {
  text-align: center;
  margin-bottom: 120px;
}

.successfulImg {
  margin: 0 auto 12px auto;
  width: 10% !important;

}

.successfulImgResubs{
    margin: 0 auto 12px auto;
  width: 20% !important;
}

.textParent{
  text-align: center;
}

.successfulHeading {
  padding-bottom: 0px;
  text-align: center;
}

.imageParent{
    text-align: center;
}

.successMsg{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
     background-color: #e4e4e4;
}

.buttonParent{
  text-align: center;
  margin-top: 15px;
}

.buttonColor{
  background-color: #367c61 !important;
  border-color: #367c61;
}