:root {
  --primary-color: #367C61;
}

@font-face {
  font-family: Sailec-Bold;
  src: url("./assets/fonts/Type\ Dynamic\ -\ Sailec\ Bold.otf") format("opentype");
  font-weight: bold;
}


@font-face {
  font-family: Sailec-Light;
  src: url("./assets/fonts/Type\ Dynamic\ -\ Sailec\ Light.otf") format("opentype");
  font-weight: light;
}



@font-face {
  font-family: Sailec-Medium;
  src: url("./assets/fonts/Type\ Dynamic\ -\ Sailec\ Medium.otf") format("opentype");
  font-weight: Medium;
}


body {
  font-family: Sailec-Bold;
  font-size: 14px;
  background: #f4f3f0;
  letter-spacing: 0 !important;
}

button {
  letter-spacing: 0 !important;

}

h1 {
  font-size: 21px
}

h2 {
  font-size: 16px
}

h3 {
  font-size: 15px
}

h4 {
  font-size: 13px
}

h5 {
  font-size: 12px
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0px;
  margin: 0px;
}

.v-align {
  vertical-align: middle;
}



.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -ms-flex: 1;
  flex: 1;
}


.sub_heading {
  color: #2B2B2B;
  font-family: Sailec-Medium;
  font-size: 16px;
  letter-spacing: 0 !important;
  line-height: 22px;
  margin-bottom: 19px;

}

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* modal */

.modal-dialog .modal-content {
  border-radius: 20px;
  background-color: #FFFFFF;
}

.fAKrR .Reschedule {
  margin-left: 9px;
  padding: 0px;
}


a {
  text-decoration: none;
}

input:active,
input:focus,
input:focus-within,
input:target,
input:hover,
input:focus-visible,
input:visited,
button:active,
button:focus,
button:focus-within,
button:target,
button:hover,
button:focus-visible,
button:visited {
  outline: 0 !important;
  box-shadow: none !important;

  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline-style: none;
  box-shadow: none;

}



.btn:disabled {
  color: #ffffff;
  background-color: #367C61 !important;
  border-color: #367C61
}




.grid_1 {
  padding-left: 0;
  padding-right: 0;
}

.row_1 {
  margin-left: 0;
  margin-right: 0;
}

.col_1 {
  padding-left: 0;
  padding-right: 0;
}


body.remove_scroll {
  overflow: hidden;
}

@media (min-width: 0px) and (max-width: 767px) {

  body.remove_pading_petprofile_main .main {
    padding-left: 0px;
    padding-right: 0px;
  }
}


@media (min-width: 0px) and (max-width: 767px) {

  .login_body .welcome_outer .content .cus_b {
    width: 100%;
  }

}


html {
  height: 100%;
}

body.body_height {
  height: 100%;
}

body.body_height #root {
  height: 100%;
}

body.body_height .LandingScreenOptions_Bg {
  height: 100%;
  overflow-y: scroll;
  padding: 0px;
}



@media (min-width: 768px) and (max-width: 1024px) {


  html {
    height: 100%;
  }

  body.body_height {
    height: 100%;
  }

  body.body_height #root {
    height: 100%;
  }

  body.body_height .LandingScreenOptions_Bg {
    height: 100%;
    overflow-y: scroll;
    padding: 0px;
  }
}

@media (min-width: 0px) and (max-width: 767px) {



  html {
    height: 100%;
  }

  body.body_height {
    height: 100%;
  }

  body.body_height #root {
    height: 100%;
  }

  body.body_height .LandingScreenOptions_Bg {

    height: 100%;
    overflow-y: scroll;
    padding: 0px;
  }

}


input,
select {
  font-size: 100%;
}




/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {

  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"] {
    font-size: 14px;
  }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {

  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"] {
    font-size: 14px;
  }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {

  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  input[type="url"] {
    font-size: 14px;
  }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {

  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  input[type="url"] {
    font-size: 14px;
  }
}


.blackandwhite {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}


.creditcardform {
  margin: 30px;
}

.gogle-error {
  color: red;
}