.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9990;
    background-color: rgba(0, 0, 0, 0.75);
}

.loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    
    background-color: white;
   
    z-index: 9999;
  
    display: flex;
    align-items: center;
    justify-content: center;
    height: 117px;
    width: 122px;
    border-radius: 50%;
}

@media (min-width: 0px) and (max-width: 767px) {
    .loader {
    height: 78.64px;
    width: 82px;
    }
}
@media (min-width: 768px) and (max-width: 1024px)  {
    
}

.spinner-loader {
    display: inline-block;
    margin-right: 11px;
    padding: auto;
}




@keyframes slide-down {
    from {
        opacity: 0;
        transform: translateY(-3rem);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}